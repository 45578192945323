function $(selector, context) {
  return (context || document).querySelector(selector);
}

function $All(selector, context) {
  var arr = [];
  arr.push.apply(arr, (context || document).querySelectorAll(selector));
  return arr;
}

var mainMenu = document.querySelector(".js-menu");
var mainMenuToggleLinks = $All(".js-toggle-menu__item > .js-disabled-link");
var mainMenuDisabledLinks = $All(".js-disabled-link");
var mainMenuToggleInput = document.querySelector(".js-showmenu__input");

const baseClass = "menu__item";
const openClass = `${baseClass}--is-open`;

//
// Open Desktop Overlay Menu Items
//
mainMenuToggleLinks.forEach(function (el) {
  el.addEventListener("click", function (event) {
    event.preventDefault();

    var openItem = document.querySelector(
      `.js-toggle-menu__listitem.${openClass}`
    );

    if (el.parentNode.classList.contains(openClass)) {
      el.parentNode.classList.remove(openClass);
    } else {
      if (openItem !== null) {
        openItem.classList.remove(openClass);
      }

      // close all other open Menus
      mainMenuToggleLinks.forEach(function (openElements) {
        openElements.parentNode.classList.remove(openClass);
      });

      el.parentNode.classList.add(openClass);
    }
  });
});

//
// Detect Click outside of Overlay Menu
//
document.addEventListener("click", function (event) {
  if (event.target.closest(".js-toggle-menu__item, .submenu")) return;

  var openItem = document.querySelector(`.js-toggle-menu__item.${openClass}`);
  if (openItem !== null) {
    openItem.classList.remove(openClass);
  }
});

//
// Detect ESC Key to close Overlay Menu
//
document.addEventListener("keyup", function (event) {
  var key = event.which || event.keyCode;
  if (key === 27) {
    var openItem = document.querySelector(`.js-toggle-menu__item.${openClass}`);
    if (openItem !== null) {
      openItem.classList.remove(openClass);
    }
  }
});

//
// Close Mobile Menu on window.resize
//
window.addEventListener("resize", function () {
  if (mainMenuToggleInput !== null && mainMenuToggleInput.checked === true) {
    mainMenuToggleInput.checked = false;
  }
});

//
// Open Desktop Overlay Menu Items
//
mainMenuDisabledLinks.forEach(function (link) {
  link.addEventListener("click", function (event) {
    event.preventDefault();
  });
});
